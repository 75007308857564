import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ProgressiveImage from 'react-progressive-image'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { GoogleMap } from '../../components'
import { media } from '../../styles/utils'
import { container, padding, bgImage, hoverState, boxShadow } from '../../styles/global'
import { resolveTailwindClass } from './utils'

const CardItem = props => {
    const { width, first_row, title, slug, cats, description, hero_image, map, subheading, page, read_more_text, ribbon } = props
    const tw_class = resolveTailwindClass(width)

    return (
        <Wrapper
            card_width={width}
            className={
                first_row
                    ? `${tw_class} card-item first-row`
                    : `${tw_class} card-item`
            }
        >
            <Container>
                {ribbon && (
                    <Ribbon><span>Successfully Funded</span></Ribbon>
                )}
                <Top>
                    <Info>{cats.length > 0 ? cats[0].name : 'Uncategorised'}</Info>

                    <Grid>
                        <Left>
                            {hero_image && (
                                <Image
                                    key={hero_image.id}
                                    src={hero_image.sizes.large}
                                >
                                    {(src, loading) => {
                                        return (
                                            <BGImage
                                                image={src}
                                                title={hero_image?.alt}
                                                style={{
                                                    opacity: loading ? 0 : 1,
                                                }}
                                                to={slug}
                                            />
                                        )
                                    }}
                                </Image>
                            )}

                            {map && (
                                <MapWrapper to={slug}>
                                    <GoogleMap map={map} />
                                </MapWrapper>
                            )}

                            {!hero_image && !map && <PlaceHolderImage to={slug} />}
                        </Left>
                        <Right>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            />

                            <Subheading
                                dangerouslySetInnerHTML={{
                                    __html: subheading,
                                }}
                            />

                            {description && (
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            )}
                        </Right>
                    </Grid>
                </Top>

                <ReadMoreLink to={slug} page={page}>
                    {read_more_text ? read_more_text : 'Read More'}
                </ReadMoreLink>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled(Link)`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Content

const Grid = styled.div``
const Left = styled.div``
const Right = styled.div`
    min-height: 160px;

    ${media.mob`
        min-height: initial;
    `}
`

const Wrapper = styled.div`
    ${tw`flex px-6 mb-12`};

    ${props => {
        if (props.card_width === 'full') return css`
            ${Grid} {
                ${tw`flex`};

                ${media.mob`
                    ${tw`block`};
                `}
            }
            ${Left} {
                ${tw`w-2/3 pr-12 pb-4`};

                ${media.mob`
                    ${tw`w-full pr-0 pb-0`};
                `}
            }
            ${Right} {
                ${tw`w-1/3`};

                ${media.mob`
                    ${tw`w-full`};
                `}
            }
        `
    }}

    /* card items on first row */
    &.first-row {
        ${tw`relative z-10 -mt-8`};

        ${media.mob`
            &:not(:first-child) {
                ${tw`mt-0`};
            }
        `}
    }
`

// Content

const Ribbon = styled.div``
const Top = styled.div``
const PlaceHolderImage = styled(Link)``
const MapWrapper = styled(Link)``
const ReadMoreLink = styled(Link)`
    ${tw`bg-darkBlue`};
    ${hoverState};

    /* Home page */
    ${props => {
        if (props.page === 'home')
            return css`
                ${tw`bg-lightBlue`};
            `
    }}
`

const Container = styled.div`
    ${tw`relative overflow-hidden flex flex-col w-full bg-white text-darkBlue`};
    ${boxShadow};

    ${Ribbon} {
        ${tw`absolute top-0 right-0 z-10`};
        width: 400px;

        span {
            ${tw`absolute block py-4 text-center bg-orange text-white leading-none`};
            top: 50px;
            right: -75px;
            width: 400px;
            transform: rotate(30deg);
            font-weight: 500;
            font-size: 1.3125rem;
        }
    }

    ${Top} {
        flex-grow: 1;
    }

    ${Top}, ${ReadMoreLink} {
        ${tw`py-5 px-6`};
    }

    ${Info}, ${ReadMoreLink} {
        ${tw`leading-none text-orange`};
        font-weight: 500; /* Medium */
        font-size: 14px;
    }

    ${Image}, ${BGImage}, ${PlaceHolderImage}, ${MapWrapper} {
        ${tw`relative flex w-full mt-6 bg-lightBlue`};
        height: 0;
        padding-bottom: 60%;
    }

    ${BGImage}, ${PlaceHolderImage} {
        ${hoverState};
    }

    ${Heading}, ${Subheading} {
        font-weight: 600; /* semibold */
        font-size: 1.375rem;
        letter-spacing: -0.02em;
    }

    ${Heading} {
        ${tw`mt-6`};
    }

    ${Subheading} {
        ${tw`mt-1 text-orange`};
    }

    ${Description} {
        ${tw`mt-4`};
        font-weight: 400; /* regular */
        font-size: 14px;
        line-height: 1.57;
    }

    ${ReadMoreLink} {
        ${tw`flex justify-end`};
    }
`



CardItem.propTypes = {
    width: PropTypes.string,
    first_row: PropTypes.bool,
    title: PropTypes.string,
    slug: PropTypes.string,
    cats: PropTypes.array,
    description: PropTypes.string,
    hero_image: PropTypes.object,
    map: PropTypes.object,
    subheading: PropTypes.string,
    page: PropTypes.string,
    read_more_text: PropTypes.string,
    ribbon: PropTypes.bool,
}

export default CardItem
