import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../../styles/utils'
import tw from 'tailwind.macro'

import { boxShadow, hoverState } from '../../../styles/global'

// Render Options

export const renderOptions = (options, updateStep, activeOption) => {	
	if (!options || !options.length) return;
	
	const items = options.map((item, i) => {
		return renderOption(i, item, updateStep, activeOption)
	})

	return (
		<Options>
			{items}
		</Options>
	)
}

const renderOption = (i, option, updateStep, activeOption) => {
	return (
		<OptionWrapper key={i}>
			<Option
				onClick={() => updateStep(option)}
				type={option.type ? option.type : false}
				active={activeOption?.slug == option?.slug}
			>
				{option.icon && (
					<Icon src={option.icon}/>
				)}
				<Heading>{option.title}</Heading>
			</Option>
		</OptionWrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Icon = styled.img``

// Options

const Options = styled.div`
	${tw`flex w-full -mx-2 mt-4 -mb-4`};

	${media.mob`
		${tw`flex-wrap mx-0`};
	`}
`

// Option

const OptionWrapper = styled.div`
	${tw`px-2 cursor-pointer`};

	${media.mob`
		${tw`w-1/2 mb-4`};
	`}
`

const Option = styled.div`
	${tw`flex items-center rounded-lg bg-darkBlue border-2 border-transparent`};
	${boxShadow};

	${Heading} {
		font-weight: 600; /* semibold */
		font-size: 13px;
		color: #8EA2B2;
	}

	${props => {
		if (props.type === 'large')
			return css`
				${tw`flex-col justify-between h-full py-4 px-6 text-center`};
				width: 170px;
				max-width: 100%;

				${media.mob`
					width: auto;
				`}

				${Heading} {
					${tw`mt-3`};
					line-height: 1.2;
				}
			`
	}}

	${props => {
		if (props.type === 'small')
			return css`
				${tw`flex-row p-2`};

				${Heading} {
					${tw`ml-3`};
					max-width: 70px;
					font-size: 12px;
				}
			`
	}}

	${props => {
		if (props.active == true) return css`
			${tw`border-orange`};
		`
	}}
`

// Step Header

export const renderStepHeader = (step) => {
	return (
		<Header>
			<Heading>{step.heading}</Heading>
			<Description
				dangerouslySetInnerHTML={{__html: step.description}}
			/>
		</Header>
	)
}

const Header = styled.div`
	${tw`mb-12`};

	${Heading}, ${Description} {
		${tw`leading-none text-white`};
		font-weight: 600; /* semibold */
	}

	${Heading} {
		font-size: 2.375rem;
		letter-spacing: -0.04em;
	}

	${Description} {
		${tw`mt-2`};
		font-size: 16px;
		letter-spacing: -0.02em;
		line-height: 1.2;
	}
`

// Step Header

export const renderInnerStepHeader = (heading, stepNum, marginTop) => {
	return (
		<InnerStepHeader className={'inner-step-header'} style={{marginTop: `${marginTop}`}}>
			<Index>{`${stepNum}.`}</Index>
			<Heading>{heading}</Heading>
		</InnerStepHeader>
	)
}

const Index = styled.div``

const InnerStepHeader = styled.div`
	${tw`flex`};

	${Index}, ${Heading} {
		font-weight: 600; /* semibold */
		font-size: 1.375rem;
		letter-spacing: -0.04em;
		line-height: 1.2;
	}

	${Index} {
		${tw`text-orange`};
	}

	${Heading} {
		${tw`ml-2 text-white`};
	}
`


