import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'
import { bgClip } from '../../styles/global'
import { navigation } from '../../styles/type'

const Breadcrumbs = props => {
    const { hide_arrow, heading, description, title } = props
    // console.log(props)

    return (
        <Wrapper hide_arrow={`${hide_arrow}`}>
            {heading && heading.link !== undefined && (
                <HeadingLink to={`${heading.link}`}>
                    <span>{heading.title}</span>
                </HeadingLink>
            )}
            {heading && heading.link === undefined && (
                <Heading>
                    <span>{heading.title}</span>
                </Heading>
            )}

            {description && description.link !== undefined && (
                <>
                    {!hide_arrow && <Arrow>></Arrow>}
                    <DescriptionLink
                        to={`${description.link}`}
                    >
                        {description.title}
                    </DescriptionLink>
                </>
            )}

            {description && description.link === undefined && (
                <>
                    {!hide_arrow && <Arrow>></Arrow>}
                    <Description>
                        {description.title}
                    </Description>
                </>
            )}

            {title && (
                <>
                    <Arrow>></Arrow>
                    <Title>{title}</Title>
                </>
            )}
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const HeadingLink = styled(Link)``
const DescriptionLink = styled(Link)``
const Title = styled.div``
const Arrow = styled.div``

const Wrapper = styled.div`
    ${tw`flex items-center mb-8`};

    ${Heading}, ${HeadingLink}, ${Arrow}, ${Description}, ${DescriptionLink}, ${Title} {
        ${navigation};
    }

    ${Heading}, ${HeadingLink} {
        ${bgClip};
        min-width: 140px;
        text-transform: capitalize;
    }

    ${Arrow} {
        ${tw`mx-2 text-orange`};

        ${media.mob`
            display: none;
        `}
    }

    ${props => {
        if (props.hide_arrow === 'true')
            return css`
                ${Description}, ${DescriptionLink} {
                    ${tw`ml-2`};
                }
            `
    }}

    ${Description}, ${DescriptionLink}, ${Title} {
        ${tw`text-orange`};

        ${media.mob`
            display: none;
        `}
    }
`

Breadcrumbs.propTypes = {
    hide_arrow: PropTypes.bool,
    heading: PropTypes.object,
    description: PropTypes.object,
    title: PropTypes.string,
}

export default Breadcrumbs
