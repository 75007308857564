import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { CardItem } from '../../components'

import { media } from '../../styles/utils'
import { container, padding, bgImage } from '../../styles/global'

const News = props => {
    const { news_posts, page } = props
    const sliced_news_posts = news_posts.slice(0, 3);
    

    const renderNewsPosts = () => {
        return sliced_news_posts.map((item, i) => {
            return (
                <CardItem
                    key={i}
                    first_row={true}
                    width={`1/3`}
                    title={item.title}
                    slug={`/news/${item.slug}/`}
                    cats={item.cats}
                    description={item.acf.short_intro}
                    hero_image={item.acf.hero_image ? item.acf.hero_image : undefined}
                    page={page ? page : undefined}
                />
            )
        })
    }

    return (
        <Wrapper page={page}>
            <Container>
                <Grid>{renderNewsPosts()}</Grid>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div`
    ${tw`bg-lightBlue`};

    ${props => {
        if (props.page === 'home')
            return css`
                ${tw`bg-darkBlue`};
            `
    }}
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex -mx-6`};

    ${media.tabPor`
        > div:nth-child(3) {
            display: none;
        }
    `}

    ${media.mob`
        ${tw`flex-wrap`};
    `}
`

News.propTypes = {
    news_posts: PropTypes.array,
    page: PropTypes.string,
}

export default News
