import React, { useState, useRef } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import Slider, { Range, Handle } from 'rc-slider'
import NumberFormat from 'react-number-format';
import 'rc-slider/assets/index.css'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { renderStepHeader, renderInnerStepHeader } from './StepShared'
import { useMount, useUpdateEffect } from 'react-use';
import { darkBlue } from '../../../styles/colors';

const AMOUNT_RANGE = [20000, 3000000]
const LENGTH_RANGE = [1, 36]

const StepSliders = ({updateStep, stepState, ...props}) => { 
	const [amount, setAmount] = useState(stepState?.[0].amount || AMOUNT_RANGE[0])
	const [length, setLength] = useState(stepState?.[1].length || LENGTH_RANGE[0])
	const amountSliderRef = useRef(null)
	const lengthSliderRef = useRef(null)

	useMount(() => {
		onChange() // update with default values on mount
	})

	const onChange = () => {
		updateStep([
			{
				amount: amount
			},
			{
				length: length
			},
		])
	}

	useUpdateEffect(() => {
		// fix slider values when navigating from other page
		if (amountSliderRef?.current.state.value !== stepState?.[0].amount) {
			setAmount(stepState?.[0].amount)
			amountSliderRef.current?.setState({ value: stepState?.[0].amount })
		}
		if (lengthSliderRef?.current.state.value !== stepState?.[1].length) {
			setLength(stepState?.[1].length)
			lengthSliderRef.current?.setState({ value: stepState?.[1].length })
		}
	}, [stepState])

	const renderHandle = (props, handleInner) => {
		const { value, dragging } = props;

		return (
			<Handle
				{...props}
			>
				<Hover
					dragging={dragging}
				>
					{handleInner(value)}
				</Hover>
			</Handle>
		)
	}

	const renderAmountSlider = () => {
		const handleInner = (value) => (
			<NumberFormat 
				value={value} 
				{...numberProps}
			/>
		)
		return (
			<>
				{renderInnerStepHeader('How much do you need?', 1)}

				<SliderWrapper>
					<Label>Grab the slider to choose >></Label>
					<Slider
						ref={amountSliderRef}
						step={10000}
						defaultValue={amount}
						min={AMOUNT_RANGE[0]}
						max={AMOUNT_RANGE[1]}
						onChange={(value) => setAmount(value)}
						onAfterChange={onChange}
						handle={(props) => renderHandle(props, handleInner)}
					>
						<Min>$20,0000</Min>
						<Max>$3,000,0000</Max>
					</Slider>
					<Total>
						{handleInner(amount)}
					</Total>
				</SliderWrapper>
			</>
		)
	}

	const renderLengthSlider = () => {
		const handleInner = (value) => (
			<>
				{value} {value > 1 ? 'Months' : 'Month'}
			</>
		)

		return (
			<>
				{renderInnerStepHeader('How long do you need the funds for?', 2, '2.5rem')}

				<SliderWrapper>
					<Label>Grab the slider to choose >></Label>
					<Slider
						ref={lengthSliderRef}
						defaultValue={length}
						min={LENGTH_RANGE[0]}
						max={LENGTH_RANGE[1]}
						onChange={(value) => setLength(value)}
						onAfterChange={onChange}
						handle={(props) => renderHandle(props, handleInner)}
					>
						<Min>1 Month</Min>
						<Max>36 Months</Max>
					</Slider>
					<Total>
						{handleInner(length)}
					</Total>
				</SliderWrapper>
			</>
		)
	}

	return (
		<Wrapper>
			<SliderStyles/>

			{renderStepHeader(props)}
			{renderAmountSlider()}
			{renderLengthSlider()}
		</Wrapper>
	)
}


// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

// Layout

const Wrapper = styled.div``

// Slider

const Min = styled.div``
const Max = styled.div``
const SliderWrapper = styled.div`
	${tw`flex items-center h-20 pl-3 mt-2 border border-orange`};

    ${media.mob`
        ${tw`px-6`};

        /* hide Label and Total */
        > div:first-child,
        > div:last-child {
            display: none;
        }
    `}

	${Min}, ${Max} {
		${tw`absolute leading-none text-white`};
		bottom: -20px;
		font-weight: 500; /* medium */
		font-size: 14px;
	}

	${Min} {
		${tw`left-0`};
	}

	${Max} {
		${tw`right-0`};
	}
`

const Label = styled.div`
	flex-shrink: 0;
	${tw`mr-8 leading-none text-orange`};
	font-weight: 500; /* medium */
	font-size: 14px;

	${media.mob`
		display: none;
	`}
`

const Total = styled.div`
	${tw`flex justify-center items-center h-full px-4 ml-8 bg-orange text-white`};
	min-width: 12rem;
	flex-shrink: 0;
	align-self: stretch;
	
	font-weight: 600; /* semibold */
	font-size: 1.875rem;
	letter-spacing: -0.02em; 
`

// Number Props

const numberProps = {
	prefix: '$',
	displayType: 'text',
	thousandSeparator: true,
	fixedDecimalScale: true ,
	decimalScale: 0
}

// Handle Tooltip

// Handle Tooltip

const Hover = styled.div`
    ${tw`absolute top-0 left-0 flex justify-center p-1 bg-white rounded-md leading-none text-darkBlue`};
	min-width: auto;
    transform: translate(-4px, 40px);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.25s ease;
	font-weight: 500; /* medium */
	font-size: 14px;

	&::before {
		content: '';
		${tw`absolute flex border-white bg-white`};
		z-index: -1;
		top: -6px;
		left: 12px;
		border-width: 0 12px 12px 0;
		transform: rotate(-135deg);
	}

    ${props => {
        if (props.dragging)
            return css`
                opacity: 1;
            `
    }}
`

const SliderStyles = createGlobalStyle`
	.rc-slider {
		.rc-slider-track,
		.rc-slider-rail {
			background: white;
		}

		.rc-slider-handle {
			${tw`w-8 h-8 -mt-4 bg-orange border-2`};
			border-color: ${darkBlue}!important;

			&:active {
				box-shadow: none !important;
			}
		}
	}
`


StepSliders.wrapper = css`

`

export default StepSliders
