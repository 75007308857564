import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { menu } from '../../data/menu'
import { MobileMenu } from '../'
import { MobileMenuContext } from '../MobileMenu/context'
import { useLocation } from 'react-use'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { media } from '../../styles/utils'
import { container, padding, hoverState, button } from '../../styles/global'
import { navigation } from '../../styles/type'

import logo from '../../assets/images/strive-logo-light.svg'
import burger from '../../assets/icons/burger-icon.svg'
import close from '../../assets/icons/close-icon.svg'

export const headerHeight = '9.375rem'

const Header = props => {
    const loansMenu = useStaticQuery(query).allWordpressLoans.nodes
    const mobileMenu = useContext(MobileMenuContext)
    const location = useLocation()

    const renderLoansMenu = () => {
        return loansMenu.map((item, i) => {
            return (
                <SubmenuItem
                    key={i}
                    to={`/loans/${item.slug}/`}
                    activeClassName={'active'}
                >
                    {item.title}
                </SubmenuItem>
            )
        })
    }

    const renderSubmenu = submenu => {
        return submenu.map((item, i) => {
            if (item.anchorLink) {
                return (
                    <SubmenuAnchorItem
                        key={i}
                        to={`${item.link}`}
                        activeClassName={'active'}
                        stripHash
                    >
                        {item.title}
                    </SubmenuAnchorItem>
                )
            } else {
                return (
                    <SubmenuItem
                        key={i}
                        to={`${item.link}`}
                        activeClassName={'active'}
                    >
                        {item.title}
                    </SubmenuItem>
                )
            }
        })
    }

    const renderMenuItems = () => {
        return menu.map((item, i) => {
            if (item.title === 'Business Loans') {
                return (
                    <SubmenuWrapper key={i}>
                        <MenuItem to={item.link} activeClassName={'active'}>
                            {item.title}
                        </MenuItem>
                        <Submenu>{renderLoansMenu()}</Submenu>
                    </SubmenuWrapper>
                )
            } else if (item.submenu !== undefined) {
                return (
                    <SubmenuWrapper key={i}>
                        <MenuItem to={item.link} activeClassName={'active'}>
                            {item.title}
                        </MenuItem>
                        <Submenu>{renderSubmenu(item.submenu)}</Submenu>
                    </SubmenuWrapper>
                )
            } else {
                return (
                    <MenuItem key={i} to={item.link} activeClassName={'active'}>
                        {item.title}
                    </MenuItem>
                )
            }
        })
    }

    const renderCtaLinks = () => {
        if (!location.pathname) return

        return (
            <>
                <MenuItem to={`/contact/`} activeClassName={'active'}>
                    Contact Us
                </MenuItem>

                <MenuItem to={`/contact/`} className={'cta'}>
                    Apply Now
                </MenuItem>
            </>
        )
    }

    return (
        <>
            {/* Mobile Menu */}
            <AnimatePresence>
                {mobileMenu.active && (
                    <MobileMenuWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <MobileMenu />
                    </MobileMenuWrapper>
                )}
            </AnimatePresence>

            {/* Desktop Menu */}
            <Wrapper>
                <Container>
                    <Grid>
                        <Left>
                            <HomeLink
                                onClick={() => {
                                    navigate('/')
                                    if (mobileMenu.active) {
                                        mobileMenu.toggleActive()
                                    }
                                }}
                            >
                                <Logo src={logo} />
                            </HomeLink>
                        </Left>
                        <Right>
                            {/* Desktop Only */}
                            <Menu>
                                {renderMenuItems()}
                                {renderCtaLinks()}
                            </Menu>

                            {/* Tablet Only */}
                            <MobileNavToggle
                                onClick={() => {
                                    mobileMenu.toggleActive()
                                }}
                            >
                                <Icon
                                    src={mobileMenu.active ? close : burger}
                                />
                            </MobileNavToggle>
                        </Right>
                    </Grid>
                </Container>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.header`
    ${tw`z-20 absolute top-0 inset-x-0`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    height: ${headerHeight};
    ${tw`flex justify-between items-center w-full`};
`

// Left

const Left = styled.div``

const HomeLink = styled.div``

const Logo = styled.img``

// Right

const Right = styled.div``

const ExternalLink = styled(OutboundLink)``
const MenuItem = styled(Link)``
const Menu = styled.div`
    ${tw`flex`};

    ${media.tab`
        display: none;
    `}

    ${MenuItem}, ${ExternalLink} {
        ${tw`inline-block py-2 px-4 mx-2 text-white`};
        ${navigation};

        &:not(.cta) {
            transition: 0.15s color ease;

            &:hover,
            &.active {
                ${tw`text-orange`};
            }
        }

        &.cta {
            ${button};
            ${navigation};
            ${tw`ml-6 mr-0 px-3`};
            ${hoverState};
        }
    }
`

const SubmenuItem = styled(Link)``
const SubmenuAnchorItem = styled(AnchorLink)``
const Submenu = styled.div``
const SubmenuWrapper = styled.div`
    ${tw`relative`};

    ${Submenu} {
        ${tw`invisible opacity-0 absolute top-100 left-0 whitespace-no-wrap flex flex-col pt-4`};
        transition: 0.15s opacity ease;

        &::before {
            content: '';
            ${tw`absolute top-0 left-0 mt-2 ml-6 w-8 bg-orange`};
            height: 1px;
        }
    }

    &:hover ${Submenu} {
        ${tw`visible opacity-100`};
    }

    ${SubmenuItem}, ${SubmenuAnchorItem} {
        ${tw`inline-block py-1 px-4 mx-2 text-white`};
        font-weight: 400; /* regular */
        font-size: 12px;
        letter-spacing: -0.02em;
        transition: 0.15s color ease;

        &:hover,
        &.active {
            ${tw`text-orange`};
        }
    }
`

// Mobile Nav Toggle

const Icon = styled.img``
const MobileNavToggle = styled.div`
    ${tw`relative z-30 w-8 h-8`};
    display: none;
    ${media.tab`
        ${tw`block`};
    `}

    ${Icon} {
        ${tw`w-full h-auto`};
    }
`

// Mobile Menu

const MobileMenuWrapper = styled(motion.div)`
    ${tw`z-20 fixed inset-0 w-full h-full bg-darkBlue`};
    overflow-y: auto;
`

export const query = graphql`
    query {
        allWordpressLoans {
            nodes {
                title
                slug
            }
        }
    }
`

export default Header
