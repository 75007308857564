export const mapTheme = [
    {
        featureType: 'all',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                saturation: '-36',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#6195a0',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [
            {
                saturation: '-7',
            },
            {
                hue: '#00ffdc',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                hue: '#00eaff',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                hue: '#00e3ff',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                hue: '#00ff07',
            },
            {
                saturation: '33',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                saturation: '-25',
            },
            {
                weight: '0',
            },
            {
                hue: '#ff0000',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [
            {
                saturation: '4',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
            {
                lightness: '90',
            },
            {
                gamma: '1',
            },
            {
                saturation: '-100',
            },
            {
                weight: '1',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#6195a0',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                saturation: '0',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                saturation: '22',
            },
            {
                hue: '#00e3ff',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#e6f3d6',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.place_of_worship',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.school',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
            {
                saturation: '0',
            },
            {
                lightness: '65',
            },
            {
                gamma: '1',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'simplified',
            },
            {
                color: '#f4d2c5',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text',
        stylers: [
            {
                color: '#7c7c7c',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f4f4f4',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#7a7a7a',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f7f7f7',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#a5a5a5',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#6c949e',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ecf6f8',
            },
        ],
    },
]
