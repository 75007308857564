import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import {
    renderOptions,
    renderStepHeader,
    renderInnerStepHeader,
} from './StepShared'

const StepOptions = ({ updateStep, stepState, ...props }) => {
    const { options, innerStepHeader } = props
    const { heading, step } = innerStepHeader
    const [selected, setSelected] = useState(stepState?.[0] || null)

    const onChange = value => {
        updateStep([value])
        setSelected(value)
    }

    return (
        <Wrapper>
            {renderStepHeader(props)}
            {renderInnerStepHeader(heading, step)}
            {renderOptions(options, onChange, selected)}
        </Wrapper>
    )
}

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

StepOptions.wrapper = css``

export default StepOptions
