import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import { find } from 'lodash'
import Slider, { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import Handle from './Handle'

import { container, bgIcon, bgImage } from '../../styles/global'
import { media } from '../../styles/utils'

class CustomSlider extends Component {

    static defaultProps = {
        min: 1,
        max: 100,
        step: 1,
        defaultValue: 100,
        onSliderChange: () => {},
    };
    
    state = {
        dragging: false
    }

    toggleDragging = (params) => {
        this.setState({
            dragging: !this.state.dragging
        })
    }
    
    
    render() {
        const {min, max, step, defaultValue, onSliderChange, onSliderChangeEnd, marks} = this.props;
        
        return (
           <Container>
               <SliderStyles/>

                <div
                    className={'slider-wrapper'}
                >
                    <Slider 
                        {...this.props}
                        // marks={this.formatMarks(marks)}
                        onChange={onSliderChange}
                        onBeforeChange={this.toggleDragging}
                        onAfterChange={this.toggleDragging}
                        handle={this.renderHandle}
                    />
                </div>
            </Container>
        )
    }

}

const Container = styled.div`

`

const tooltip = css`
    position: relative;
    font-size: 15px;
    font-family: 'Maax Medium';
    background-color: #fdfdfd;
    border: solid 1px rgba(28, 30, 38, 0.1);
    padding: 10px 20px;
    min-width: 140px;
    border-radius: 5px;
    top: -20px;
    border: 1px solid #D3D3D5;
    box-shadow: 0 2px 11px 0 rgba(0,0,0,0.15);

    &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 8px;
        margin-left: -8px;
    }

    &:before {
        border-color: rgba(211, 211, 213, 0);
        border-width: 9px;
        margin-left: -9px;
    }
`

const MarkHover = styled.div`
    ${tooltip}    
    opacity: 0;
    transition: 0.25s ease opacity;

    /* Hide mark tooltip if dragging */

    ${props => {
        if (props.dragging) return css`
            opacity: 0 !important;
        `
    }}
`;

const HandleHover = styled.div`
    ${tooltip}  
    min-width: 113px;
    box-sizing: border-box;
    text-align: center;
    transform: translateY(-35px);
    padding: 10px 0px;
    min-width: 120px;
`

const SliderStyles = createGlobalStyle`
    .slider-wrapper {
        display: flex; 
        align-items: center;
        position: relative;

        .rc-slider {
            height: 12px;
            padding: 0;
            position: relative;
            border-radius: 2px;
            transform: translateY(-5px);
            z-index: 2;

            .rc-slider-mark-text {
                transform: translate(calc(-50% + 4px), -63px) !important;
                height: 128px;
                flex: 1;
                z-index: 10;
                max-width: 32px;

                > * {
                    transform: translateX(-54px);
                }

                &:hover {
                    > * {
                        opacity: 1;
                    }
                }
            }

            .rc-slider-rail,
            .rc-slider-track  {
                display: none;
            }

            /* Hide default handle */

            .rc-slider-handle {
                &, &:active {
                    border: 0;
                    background: none;
                    box-shadow: none;
                }
            }
        }

        /* Custom Handle */

        .custom-handle {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 48px;    
            border: none;
            border-radius: 4px;
            cursor: grab;
            transform: translate(-39%, -14px);
            z-index: 11;
            position: relative;
            box-shadow: 0px 4px 8px rgba(28, 30, 38, 0.1);
            
            .value {
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.16px;
                color: white;
                font-family: 'Maax Medium';
            }

            ${HandleHover} {
                display: none;
            }

            &.alignRight {
                position: absolute;
                left: auto;
                right: 0;
                transform: translate(50%, -14px);
            }
        }

        /* Custom Slider Dot */
        
        .rc-slider-step {
            left: 8px;
            width: calc(100% - 14px);
        }

        .rc-slider-dot {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.7);
            border: 0;
            z-index: 1;
            transform: translateY(3px);
            background: red;          
        }

    }
`

export default CustomSlider
