import React, { useState, useRef } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { navigate } from 'gatsby'
import Slider, { Range, Handle } from 'rc-slider'
import NumberFormat from 'react-number-format'
import 'rc-slider/assets/index.css'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    button,
    boxShadow,
} from '../../styles/global'
import { darkBlue } from '../../styles/colors'
import { useMount, useUpdateEffect } from 'react-use'

const AMOUNT_RANGE = [20000, 3000000]
const LENGTH_RANGE = [1, 36]

const LoanWidget = (props) => {
    const [amount, setAmount] = useState(AMOUNT_RANGE[0])
    const [length, setLength] = useState(LENGTH_RANGE[0])

    const renderHandle = (props, handleInner) => {
        const { value, dragging } = props

        return (
            <Handle {...props}>
                <Hover dragging={dragging}>{handleInner(value)}</Hover>
            </Handle>
        )
    }

    const renderAmountSlider = () => {
        const handleInner = value => (
            <NumberFormat value={value} {...numberProps} />
        )
        return (
            <AmountSlider>
                <InnerStepHeader size={props.size ? props.size : ''}>
                    <Index>{`1.`}</Index>
                    {props.type === 'partners' && (
                        <Heading>How much do they need?</Heading>
                    )}
                    {props.type !== 'partners' && (
                        <Heading>How much do you need?</Heading>
                    )}
                </InnerStepHeader>

                <SliderWrapper size={props.size ? props.size : ''}>
                    <Label>Grab the slider to choose >></Label>
                    <Slider
                        step={10000}
                        defaultValue={amount}
                        min={AMOUNT_RANGE[0]}
                        max={AMOUNT_RANGE[1]}
                        onChange={value => setAmount(value)}
                        // onAfterChange={onChange}
                        handle={props => renderHandle(props, handleInner)}
                    >
                        <Min>$20,0000</Min>
                        <Max>$3,000,0000</Max>
                    </Slider>
                    <Total>{handleInner(amount)}</Total>
                </SliderWrapper>
            </AmountSlider>
        )
    }

    const renderLengthSlider = () => {
        const handleInner = value => (
            <>
                {value} {value > 1 ? 'Months' : 'Month'}
            </>
        )

        return (
            <LengthSlider size={props.size ? props.size : ''}>
                <InnerStepHeader size={props.size ? props.size : ''}>
                    <Index>{`2.`}</Index>
                    {props.type === 'partners' && (
                        <Heading>How long do they need the funds for?</Heading>
                    )}
                    {props.type !== 'partners' && (
                        <Heading>How long do you need the funds for?</Heading>
                    )}
                </InnerStepHeader>

                <SliderWrapper size={props.size ? props.size : ''}>
                    <Label>Grab the slider to choose >></Label>
                    <Slider
                        defaultValue={length}
                        min={LENGTH_RANGE[0]}
                        max={LENGTH_RANGE[1]}
                        onChange={value => setLength(value)}
                        // onAfterChange={onChange}
                        handle={props => renderHandle(props, handleInner)}
                    >
                        <Min>1 Month</Min>
                        <Max>36 Months</Max>
                    </Slider>
                    <Total>{handleInner(length)}</Total>
                </SliderWrapper>
            </LengthSlider>
        )
    }

    const navigateToWizard = () => {
        // user hasn't touched sliders - go to step 1
        if (amount === 20000 && length === 1) {
            navigate('/loan-selector')

        // user has touched sliders - go to step 2
        } else {
            const wizardData = {
                steps: [[{ amount: amount }, { length: length }]],
            }
    
            navigate('/loan-selector', {
                state: {
                    wizardData: {
                        selections: wizardData,
                        activeStep: 1,
                    },
                },
            })
        }
    }

    return (
        <Wrapper size={props.size ? props.size : ''}>
            <SliderStyles />
            <Heading>Get Started</Heading>
            {props.type === 'partners' && (
                <Subheading>Build your client's loan</Subheading>
            )}
            {props.type !== 'partners' && (
                <Subheading>Build your bespoke loan</Subheading>
            )}
            {renderAmountSlider()}
            {renderLengthSlider()}
            <Button onClick={() => navigateToWizard()}>Get Started</Button>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``

// Get Started Button

const Button = styled.div`
    ${tw`inline-block mt-16 mx-auto py-3 px-6 leading-none bg-white rounded-md text-orange cursor-pointer`};
    font-weight: 600; /* semibold */
    font-size: 1.375rem;
    letter-spacing: -0.02em;
    ${hoverState};
`

// Layout

const Wrapper = styled.div`
    ${tw`mx-auto pt-16 pb-32 text-center`};
    max-width: 820px;

    ${props => {
        if (props.size === 'mini')
            return css`
                ${tw`py-0`};
            `
    }}

    ${Heading}, ${Subheading} {
        ${tw`text-white`};
    }

    ${Heading} {
        font-weight: 500; /* medium */
        font-size: 15px;
    }

    ${Subheading} {
        ${tw`my-10`};
        font-weight: 600; /* semibold */
        font-size: 1.9375rem;

        ${props => {
            if (props.size === 'mini')
                return css`
                    ${tw`mt-4 mb-10`};
                    font-size: 1.5rem;
                `
        }}
    }

    ${Button} {
        ${props => {
            if (props.size === 'mini')
                return css`
                    ${tw`mt-6 text-darkBlue`};
                `
        }}
    }
`

// Amount Slider

const AmountSlider = styled.div``

// Length Slider

const LengthSlider = styled.div`
    ${tw`mt-10`};


    ${props => {
        if (props.size === 'mini')
            return css`
                ${tw`mt-4`};
            `
    }}
`

// Inner Step Header

const Index = styled.div``
const InnerStepHeader = styled.div`
    ${tw`flex text-left`};

    ${props => {
        if (props.size === 'mini')
            return css`
                ${tw`justify-center mb-5 py-3 px-1 text-center bg-white`};
            `
    }}

    ${Index}, ${Heading} {
        ${tw`leading-none`};
        font-weight: 600; /* semibold */
        font-size: 1.375rem;
        letter-spacing: -0.04em;

        ${props => {
            if (props.size === 'mini')
                return css`
                    font-weight: 500; /* medium */
                    font-size: 14px;
                    letter-spacing: initial;
                `
        }}
    }

    ${Index} {
        ${tw`text-orange`};

        ${props => {
            if (props.size === 'mini')
                return css`
                    display: none;
                `
        }}
    }

    ${Heading} {
        ${tw`ml-2 text-white`};

        ${props => {
            if (props.size === 'mini')
                return css`
                    ${tw`ml-0 text-darkBlue`};
                `
        }}
    }
`

// Slider

const Min = styled.div``
const Max = styled.div``
const SliderWrapper = styled.div`
    ${tw`flex items-center h-20 pl-3 mt-2 border border-orange`};

    ${props => {
        if (props.size === 'mini')
            return css`
                ${tw`pl-0`};

                /* hide Label and Total */
                > div:first-child,
                > div:last-child {
                    display: none;
                }

                .rc-slider .rc-slider-handle {
                    ${tw`bg-darkBlue`};
                }
            `
    }}

    ${media.mob`
        ${tw`px-6`};

        /* hide Label and Total */
        > div:first-child,
        > div:last-child {
            display: none;
        }
    `}

    ${Min}, ${Max} {
        ${tw`absolute leading-none text-white`};
        bottom: -20px;
        font-weight: 500; /* medium */
        font-size: 14px;

        ${props => {
            if (props.size === 'mini')
                return css`
                    bottom: initial;
                    top: -35px;
                `
        }}
    }

    ${Min} {
        ${tw`left-0`};
    }

    ${Max} {
        ${tw`right-0`};
    }
`

const Label = styled.div`
    flex-shrink: 0;
    ${tw`mr-8 leading-none text-orange`};
    font-weight: 500; /* medium */
    font-size: 14px;
`

const Total = styled.div`
    ${tw`flex justify-center items-center h-full px-4 ml-8 bg-orange text-white`};
    min-width: 12rem;
    flex-shrink: 0;
    align-self: stretch;

    font-weight: 600; /* semibold */
    font-size: 1.875rem;
    letter-spacing: -0.02em;
`

// Number Props

const numberProps = {
    prefix: '$',
    displayType: 'text',
    thousandSeparator: true,
    fixedDecimalScale: true,
    decimalScale: 0,
}

// Handle Tooltip

const Hover = styled.div`
    ${tw`absolute top-0 left-0 flex justify-center p-1 bg-white rounded-md leading-none text-darkBlue`};
    min-width: auto;
    transform: translate(-4px, 40px);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.25s ease;
    font-weight: 500; /* medium */
    font-size: 14px;

    &::before {
        content: '';
        ${tw`absolute flex border-white bg-white`};
        z-index: -1;
        top: -6px;
        left: 12px;
        border-width: 0 12px 12px 0;
        transform: rotate(-135deg);
    }

    ${props => {
        if (props.dragging)
            return css`
                opacity: 1;
            `
    }}
`

const SliderStyles = createGlobalStyle`
	.rc-slider {
		.rc-slider-track,
		.rc-slider-rail {
			background: white;
		}

		.rc-slider-handle {
			${tw`w-8 h-8 -mt-4 bg-orange border-2`};
			border-color: ${darkBlue}!important;

			&:active {
				box-shadow: none !important;
			}
		}
	}
`

export default LoanWidget
