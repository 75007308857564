import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'
import { bgIcon } from '../../styles/global'
import { subheading } from '../../styles/type'

class Input extends Component {
    state = {
        focused: false,
    }

    onBlur = () => {
        const { toggleFocus } = this.props

        this.setState({ focused: false })

        if (toggleFocus) {
            toggleFocus(false)
        }
    }

    onFocus = () => {
        const { toggleFocus } = this.props

        this.setState({ focused: true })

        if (toggleFocus) {
            toggleFocus(true)
        }
    }

    shouldComponentUpdate() {
        // Stop stripe fields from updating

        if (this.props.stripeOptions) {
            return false
        }

        return true
    }

    onChangeValue = e => {
        const { validator, onChangeValue } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    shouldShowError = () => {
        const { focused } = this.state
        const { shouldValidate, isValid } = this.props

        if (shouldValidate === true && !isValid) {
            return true
        }

        if (shouldValidate && !isValid && !focused) {
            return true
        }
    }

    getInputProps = () => {
        const {
            value,
            label,
            type,
            placeholder,
            className,
            disabled,
            name,
            locale,
            id,
            icon,
        } = this.props
        const showError = this.shouldShowError()

        return {
            className: className,
            id: name,
            type: type,
            placeholder: placeholder,
            value: value,
            disabled: disabled,
            onChange: !disabled && this.onChangeValue,
            error: showError,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
        }
    }

    render() {
        const { focused } = this.state
        const {
            errorMessage,
            id,
            icon,
            type,
            label,
            validator,
            component,
        } = this.props
        const showError = this.shouldShowError()
        const CustomComponent = component || false
        // console.log(this.props)

        return (
            <Wrapper id={id} className={[id, 'dynamic-field']}>
                <FieldWrapper className={'field-wrapper'}>
                    <Field className={'field'} error={showError}>
                        {!CustomComponent && (
                            <>
                                {type == 'textarea' ? (
                                    <TextField {...this.getInputProps()} />
                                ) : (
                                    <InputField {...this.getInputProps()} />
                                )}
                            </>
                        )}

                        {CustomComponent && (
                            <CustomComponent
                                {...this.props}
                                {...this.props.stripeOptions}
                            />
                        )}
                    </Field>
                </FieldWrapper>

                {showError && <Error className={'error'}>{errorMessage}</Error>}
            </Wrapper>
        )
    }
}

export const Wrapper = styled.div`
    width: 100%;
`

const FieldWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

// Field

export const inputStyle = css`
    ${tw`w-full py-2 px-3 bg-lightBlue rounded-md border-none appearance-none shadow-none`};
    width: 100%;

    &,
    &::placeholder {
        ${tw`leading-none`};
        font-weight: 400; /* regular */
        font-size: 16px;
        letter-spacing: -0.02rem;
        color: #859199;
    }

    &::placeholder {
        /* color: black; */
    }

    &:focus {
        outline: none;
    }

    ${props => {
        if (props.disabled)
            return css`
                pointer-events: none;

                &,
                &::placeholder {
                    /* color: rgba(0, 0, 0, 0.2); */
                }
            `
    }}
`

const Field = styled.div`
    ${tw`flex w-full`};

    select {
        ${inputStyle}
    }
`

export const InputField = styled.input`
    ${inputStyle} /* min-width: 110px; */
`

export const TextField = styled.textarea`
    ${inputStyle}
    resize: vertical;
`

// Error

const message = css`
    font-size: 14px;
`

export const Error = styled.div`
    ${message}
    ${tw`text-errorRed`};
    margin-top: 8px;
	margin-left: auto;
`

export default Input
