import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAPS_KEY } from '../../constants'
import { mapTheme } from '../../styles/map'
import { useLocation } from 'react-use'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'

import mapMarker from '../../assets/icons/map-marker.svg'

const GoogleMap = props => {
    const location = useLocation()
    const { map, padding } = props
    if (!location || !map) return

    const mapProps = {
        defaultZoom: 15,
        defaultCenter: {
            lat: parseFloat(map.lat),
            lng: parseFloat(map.lng),
        },
        bootstrapURLKeys: {
            key: GOOGLE_MAPS_KEY,
        },
        options: {
            styles: mapTheme,
            gestureHandling: 'none',
            disableDefaultUI: true,
        },
    }

    const markerProps = {
        lat: parseFloat(map.lat),
        lng: parseFloat(map.lng),
    }

    return (
        <Map padding={padding ? padding : false}>
            <GoogleMapReact {...mapProps}>
                <Marker {...markerProps} src={mapMarker} />
            </GoogleMapReact>
        </Map>
    )
}

const Map = styled.div`
    ${tw`absolute inset-0 w-full h-full`};

    ${props => {
        if (props.padding)
            return css`
                ${tw`p-4`};
            `
    }}

    &,
    * {
        ${tw`pointer-events-none`};
    }
`

const Marker = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: auto;
    transform: translate(-50%, -100%);
`

GoogleMap.propTypes = {
    map: PropTypes.object,
    padding: PropTypes.bool,
}

export default GoogleMap
