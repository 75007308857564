import { forEach, inRange, maxBy, keys, find } from 'lodash'

import { 
    StepOptions,
    StepSliders,
    StepText,
    StepMultiOptions,
    StepComplete
} from './templates'
import { plans } from './data';

export const resolveStep = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'options': StepOptions,
        'sliders': StepSliders,
        'text': StepText,
        'multiOptions': StepMultiOptions,
        'complete': StepComplete
    }
    
    return blocks[layout]
}


export const getPlanByScore = (wizardState) => {
    // initial scores
    let scores = {
        shortTerm: 0,
        mediumTerm: 0,
        longTerm: 0
    }

    // init excluded loans array
    let excludedLoans = []

    // score steps
    forEach(wizardState.steps, (step) => {
        forEach(step, (innerStep) => {
            const { scoring, amount, length, excludeLoans, scoreOverride } = innerStep;

            // Exclude loan (from data)

            if (excludeLoans) {
                excludeLoans.forEach((item, i) => {
                    excludedLoans.push(item)
                })
            }

            // Option scoring
                
            if (scoring) {
                forEach(scoring, (score) => {
                    scores[score.type] = scores[score.type] + score.points
                })
            }

            // Length slider scoring

            if (length) {
                const lengthScoreRanges = {
                    shortTerm: [0, 5],
                    mediumTerm: [6, 12],
                    longTerm: [13, 36]
                }

                forEach(lengthScoreRanges, (range, key) => {
                    if (inRange(length, range[0], range[1])) scores[key] = scores[key] + 2
                })
            }

            // Amount slider scoring

            if (amount) {
                const amountScoreRanges = {
                    shortTerm: [20000, 50000],
                    mediumTerm: [51000, 250000],
                    longTerm: [251000, 3000001]
                }

                forEach(amountScoreRanges, (range, key) => {
                    if (inRange(amount, range[0], range[1])) {
                        // exclude loans
                        switch (key) {
                            case 'shortTerm':
                                excludedLoans.push('mediumTerm', 'longTerm')
                                scores['shortTerm'] = scores['shortTerm'] + 2
                                break

                            case 'mediumTerm':
                                excludedLoans.push('longTerm')
                                scores['shortTerm'] = scores['shortTerm'] + 2
                                scores['mediumTerm'] = scores['mediumTerm'] + 2
                                break

                            case 'longTerm':
                                excludedLoans.push('mediumTerm')
                                scores['shortTerm'] = scores['shortTerm'] + 2
                                scores['longTerm'] = scores['longTerm'] + 2
                                break

                            default:
                                break
                        }
                    }
                })                
            }
        })
    })

    // code output

    let logicLogger = ''

    if (scores) {
        logicLogger = logicLogger+`<p><strong>Scores:</strong></p><p>`
        Object.keys(scores).forEach(function(item) {
            if (excludedLoans.includes(item)) {
                logicLogger = logicLogger+`<strike>${item}: ${scores[item]}</strike><br>`
            } else {
                logicLogger = logicLogger+`${item}: ${scores[item]}<br>`
            }
        })
        logicLogger = logicLogger+`</p>`
    }

    // Exclude loans from results

    forEach(scores, (value, key) => {
        if (excludedLoans.includes(key)) {
            delete scores[key]
        }
    })

    // Get loan with highest score 

    const type = maxBy(keys(scores), o => scores[o])

    // return recommended loan

    return [find(plans, {type: type}), logicLogger]
}

