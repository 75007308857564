import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from '../../styles/utils'
import { container, padding } from '../../styles/global'

const Disclaimer = props => {
    const { disclaimer_text } = props

    return (
        <Wrapper>
            <Container>
                {disclaimer_text && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: disclaimer_text,
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`mt-8`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Text = styled.div`
    ${tw`text-white leading-normal`};
    font-size: 12px;
    font-weight: 500; /* medium */
`

Disclaimer.propTypes = {
    disclaimer_text: PropTypes.string,
}

export default Disclaimer
