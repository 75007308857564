import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { bgClip, hoverState, boxShadow } from '../../../styles/global'
import {
    renderOptions,
    renderStepHeader,
    renderInnerStepHeader,
} from './StepShared'
import { getPlanByScore } from '../utils'
import { testWizardState } from '../data'

const StepComplete = ({ wizardState, ...props }) => {
    const data = getPlanByScore(wizardState)
    const plan = data[0]

    const navigateToContact = () => {
        navigate('/contact', {
            state: {
                wizardData: {
                    selections: wizardState,
                    recommendedPlan: plan,
                },
            },
        })
    }

    return (
        <Wrapper>
            <Info>
                <Heading>We recommend a</Heading>
                <Subheading
                    dangerouslySetInnerHTML={{
                        __html: plan.heading,
                    }}
                />
            </Info>

            <Content>
                <Left>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: plan.subheading,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: plan.description,
                        }}
                    />
                </Left>

                <Right>
                    <Button onClick={navigateToContact}>
                        <span>Next Step</span>
                    </Button>
                </Right>
            </Content>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Button = styled.div``

// Layout

const Wrapper = styled.div`
    ${tw`mb-16`};
`

// Info

const Info = styled.div`
    ${Heading} {
        ${tw`text-white`};
        font-weight: 600; /* semibold */
        font-size: 1.4375rem;
        letter-spacing: -0.04em;
    }

    ${Subheading} {
        ${tw`mt-4 text-orange`};
        max-width: 650px;
        font-weight: 600; /* semibold */
        font-size: 3.4375rem;
        letter-spacing: -0.02em;
    }
`

// Content

const Left = styled.div``
const Right = styled.div``

const Content = styled.div`
    ${tw`flex items-center mt-12 p-6 pr-0 bg-darkBlue`};
    max-width: 1100px;
    ${boxShadow};

    ${media.mob`
        ${tw`block pr-6`};
    `}

    ${Left} {
        ${tw`pr-16`};

        ${media.mob`
            ${tw`pr-0`};
        `}
    }

    ${Right} {
        flex-grow: 1;
        flex-shrink: 0;

        ${media.mob`
            ${tw`mt-8`};
        `}
    }

    ${Heading}, ${Description} {
        ${tw`text-white`};
        font-weight: 600; /* semibold */
        line-height: 1.2;
        letter-spacing: -0.02em;
    }

    ${Heading} {
        font-size: 1.25rem;
    }

    ${Description} {
        ${tw`mt-4`};
        font-size: 16px;
    }

    ${Button} {
        ${bgClip};
        ${tw`-mr-6 py-3 px-8 cursor-pointer`};
        font-weight: 600; /* semibold */
        font-size: 1.375rem;
        letter-spacing: -0.04em;
    }
`

StepComplete.wrapper = css``

export default StepComplete

// change step function in loanwizard, match the index with that step (i in the map will match the change step argument)
