import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { parseACF } from '../../utils'

import { media } from '../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    button,
} from '../../styles/global'
import { navigation } from '../../styles/type'

import logo from '../../assets/images/strive-logo-light.svg'
import fbaaLogo from '../../assets/images/fbaa-logo.svg'

const Footer = props => {
    const loansMenu = useStaticQuery(query).allWordpressLoans.nodes

    const renderLoansMenu = () => {
        return loansMenu.map((item, i) => {
            return (
                <MenuItem
                    key={i}
                    to={`/loans/${item.slug}/`}
                    activeClassName={'active'}
                >
                    {item.title}
                </MenuItem>
            )
        })
    }

    return (
        <Wrapper>
            <Container>
                <Top>
                    <Left>
                        <HomeLink to="/">
                            <Logo src={logo} />
                        </HomeLink>
                    </Left>

                    <Right>
                        <Supporting>
                            <Description>Proudly supporting</Description>
                            <a href="https://www.fbaa.com.au/" target="_blank">
                                <Logo src={fbaaLogo} />
                            </a>
                        </Supporting>
                    </Right>
                </Top>
                <Bottom>
                    <Left>
                        <Menu>
                            <MenuItem
                                to={`/investments/`}
                                activeClassName={'active'}
                                className={'heading'}
                            >
                                Investments
                            </MenuItem>
                            <MenuItem
                                to={`/investments/`}
                                activeClassName={'active'}
                            >
                                Completed Investments
                            </MenuItem>
                        </Menu>
                        <Menu>
                            <MenuItem
                                to={`/business-loans/`}
                                activeClassName={'active'}
                                className={'heading'}
                            >
                                Business Loans
                            </MenuItem>
                            {renderLoansMenu()}
                            <MenuItem
                                to={`/loan-selector/`}
                                activeClassName={'active'}
                                className={'cta'}
                            >
                                Loan Selector
                            </MenuItem>
                        </Menu>
                        <Menu>
                            <MenuItem
                                to={`/brokers/`}
                                activeClassName={'active'}
                                className={'heading'}
                            >
                                Brokers
                            </MenuItem>
                        </Menu>
                    </Left>
                    <Right>
                        <Menu>
                            <MenuItem to={`/about/`} activeClassName={'active'}>
                                About Strive
                            </MenuItem>
                            <MenuItem to={`/news/`} activeClassName={'active'}>
                                News
                            </MenuItem>
                            <MenuItem
                                to={`/contact/`}
                                activeClassName={'active'}
                            >
                                Contact Us
                            </MenuItem>
                            <MenuItem
                                to={`/privacy-policy/`}
                                activeClassName={'active'}
                            >
                                Privacy Policy
                            </MenuItem>
                        </Menu>
                    </Right>
                </Bottom>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.footer`
    ${tw`py-16`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Shared

const Left = styled.div``
const Right = styled.div``
const Description = styled.div``

// Top

const Supporting = styled.div``
const HomeLink = styled(Link)``
const Logo = styled.img``
const Top = styled.div`
    ${tw`flex justify-between items-center pb-8 border-b border-orange`};

    ${media.mob`
        ${tw`flex-wrap`};
    `}

    ${Supporting} {
        ${tw`flex items-center`};

        ${media.mob`
            ${tw`py-4`};
        `}

        ${Description} {
            ${tw`mt-5 text-mediumBlue`};
            font-weight: 600; /* semibold */
            font-size: 14px;
        }

        a {
            ${hoverState};
        }

        ${Logo} {
            ${tw`ml-4`};
        }
    }
`

// Bottom

const MenuItem = styled(Link)``
const Menu = styled.div``
const Bottom = styled.div`
    ${tw`flex justify-between pt-8`};

    ${media.mob`
        ${tw`flex-wrap`};
    `}

    ${Menu} {
        ${tw`flex flex-col`};
    }

    ${MenuItem} {
        ${tw`py-1 text-mediumBlue`};
        ${navigation};
        ${hoverState};

        /* &.active {
            ${tw`underline`};
        } */

        ${media.mob`
            padding-top: 5px;
            padding-bottom: 5px;
        `}

        &.cta {
            ${tw`text-orange`};
        }
    }

    ${Left} {
        ${tw`flex -mx-8 pb-4`};

        ${media.tabPor`
            ${tw`w-3/4`};
        `}

        ${media.mob`
            ${tw`flex-col w-full mx-0 pb-0`};
        `}

        ${Menu} {
            ${tw`px-8`};

            ${media.mob`
                ${tw`px-0 py-4`};
            `}
        }

        ${MenuItem} {
            ${tw`pl-1`};

            &.heading {
                ${tw`pl-0 text-white`};
            }
        }
    }

    ${Right} {
        ${tw`px-8 pb-4 border-l border-mediumBlue`};

        ${media.tabPor`
            ${tw`w-1/4`};
        `}

        ${media.mob`
            ${tw`w-full px-0 pb-0 py-4 border-none`};
        `}
    }
`

export const query = graphql`
    query {
        allWordpressLoans {
            nodes {
                title
                slug
            }
        }
    }
`

export default Footer
