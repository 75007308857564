import React from 'react'
import { resolveBlock } from './utils'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

const Block = props => {
    const { layout } = props
    const BlockLayout = resolveBlock(layout)
    if (!BlockLayout) return

    return BlockLayout && <BlockLayout {...props} />
}

export default Block
