import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, bgClip } from '../../../styles/global'
import { renderStepHeader, renderInnerStepHeader } from './StepShared'

import warning from '../../../assets/icons/loan-wizard/warning-icon.svg'

const StepText = ({ updateStep, stepState, ...props }) => {
    const { innerStepHeader } = props
    const { heading, step } = innerStepHeader

    const onChange = e => {
        const value = e.target.value

        updateStep([
            {
                text: value,
            },
        ])
    }

    return (
        <Wrapper>
            {renderStepHeader(props)}
            {renderInnerStepHeader(heading, step)}

            <Content>
                <Left>
                    <TextBox
                        value={stepState?.[0].text}
                        placeholder={'Type here'}
                        onChange={onChange}
                    />
                </Left>

                <Right>
                    <Warning>
                        <Heading>
                            <Icon src={warning} />
                            <span>Before you continue</span>
                        </Heading>

                        <Subheading>
                            Strive Financial Loans are for business use only.
                        </Subheading>

                        <Description
                            dangerouslySetInnerHTML={{
                                __html: `
							<p>We only offer loans for business purposes so we can't help if this loan is for your own use.</p>
							<p>If that is the case, you can exit the tool below.</p>
						`,
                            }}
                        />

                        <BackLink
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
							<span>
                            	Exit this tool
							</span>
                        </BackLink>
                    </Warning>
                </Right>
            </Content>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Button = styled.div``

const Icon = styled.img``

// Layout

const Wrapper = styled.div``

const Left = styled.div``
const Right = styled.div``

const Content = styled.div`
    ${tw`flex mt-4`};

    ${media.mob`
        ${tw`block`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2`};

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Right} {
        ${tw`pl-8`};

        ${media.mob`
            ${tw`mt-4 pl-0`};
        `}
    }
`

// Text Box

const TextBox = styled.textarea`
    ${tw`w-full h-full p-3`};
	min-height: 240px;

    ${media.mob`
        min-height: 160px;
    `}

    &,
    &::placeholder {
        ${tw`leading-none text-darkBlue`};
        font-weight: 600; /* semibold */
        font-size: 14px;
    }

    &:focus {
        outline: none;
    }
`

// Warning

const BackLink = styled.div``
const Warning = styled.div`
    ${tw`max-w-md`};

    ${Heading} {
        ${tw`flex items-center`};

        ${Icon} {
        }

        span {
            ${tw`ml-3 text-orange`};
            font-weight: 600; /* semibold */
            font-size: 1.375rem;
            letter-spacing: -0.04em;
        }
    }

    ${Subheading}, ${Description} {
        ${tw`mt-4 text-white`};
        font-weight: 600; /* semibold */
        line-height: 1.4;
        letter-spacing: -0.02em;
    }

    ${Subheading} {
        font-size: 17px;
    }

    ${Description} {
        font-size: 13px;
    }

    ${BackLink} {
        ${bgClip};
		${hoverState};
        ${tw`mt-5 py-3 px-6 leading-none cursor-pointer`};
        font-weight: 600; /* semibold */
        font-size: 15px;
        letter-spacing: -0.04em;
    }
`

StepText.wrapper = css``

export default StepText
