import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'

const BlockImage = props => {
    const { image } = props
    // console.log(props)

    return (
        <Wrapper>
                {image && (
                    <Image key={image.id} src={image.sizes.medium2}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    alt={image?.alt}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
        </Wrapper>
    )
}

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div``

export default BlockImage
