import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { filter, some } from 'lodash'

import { media } from '../../styles/utils'
import { container, padding, boxShadow } from '../../styles/global'

import quoteLeftIcon from '../../assets/icons/quote-left-icon.svg'
import quoteRightIcon from '../../assets/icons/quote-right-icon.svg'

const Testimonial = props => {
    const { testimonials, category } = props
    let data = testimonials.data
    let testimonial

    // filter by category if required
    if (category) {
        data = filter(data, function(o) {
            return some(o.cats, function(cat) {
                return cat.slug === category
            })
        })
    }

    // get random testimonial
    testimonial = data[Math.floor(Math.random() * data.length)]

    return (
        <Wrapper bgColor={props.bgColor}>
            <Container>
                {testimonial.image && (
                    <Image
                        key={testimonial.image.id}
                        src={testimonial.image.sizes.large}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    alt={testimonial.image?.alt}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}

                {!testimonial.image && (
                    <PlaceholderImage>
                        <Icon src={quoteLeftIcon} />
                        <Icon src={quoteRightIcon} />
                    </PlaceholderImage>
                )}
                <Description
                    dangerouslySetInnerHTML={{
                        __html: `“${testimonial.text}”`,
                    }}
                />

                {testimonial.source && (
                    <Info 
                        dangerouslySetInnerHTML={{
                            __html: testimonial.source,
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div`
    ${tw`pb-32 bg-lightBlue text-darkBlue`};

    ${media.mob`
        ${tw`pb-24`};
    `}

    ${props => {
        if (props.bgColor === 'dark')
            return css`
                ${tw`bg-darkBlue text-white`};
            `
    }}
`

const PlaceholderImage = styled.div``
const Icon = styled.img``
const Container = styled.div`
    ${container}
    ${padding}
    ${tw`flex flex-col items-center`};

    ${Image}, ${LoadedImage} {
        ${tw`-mt-16 border-2 border-white`};
        ${boxShadow};
        width: 230px;
        border-radius: 50%;
    }

    ${PlaceholderImage} {
        ${tw`flex justify-center items-center w-40 h-40 -mt-16 bg-white border border-darkBlue rounded-full`};
        border-width: 12px;
        ${boxShadow};

        ${Icon} {
            ${tw`w-10`};

            &:first-child {
                ${tw`-mt-3 mr-3`};
            }

            &:last-child {
                ${tw`mt-3`};
            }
        }
    }

    ${Description} {
        ${tw`mt-8 text-center`};
        max-width: 672px;

        &, * {
            font-weight: 600; /* semibold */
            font-size: 1.4375rem;
            letter-spacing: -0.02em;
            line-height: 1.22;
        }

        br {
            ${tw`block w-full`};
            content: '';
            line-height: 1.4375rem;
            margin-top: 1.4375rem;
        }
    }

    ${Info} {
        ${tw`mt-6 text-orange uppercase`};
        font-weight: 600; /* semibold */
        letter-spacing: -0.02em;
    }
`

Testimonial.propTypes = {
    bgColor: PropTypes.string,
    testimonials: PropTypes.object,
    category: PropTypes.string,
}

export default Testimonial
