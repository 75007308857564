export const menu = [
    {
        title: 'Business Loans',
        link: '/business-loans/',
    },
    {
        title: 'Investments',
        link: '/investments/',
    },
    {
        title: 'Brokers',
        link: '/brokers/',
    },
    {
        title: 'About',
        link: '/about/',
        submenu: [
            {
                title: 'Meet the Directors',
                link: '/about#directors',
                anchorLink: true,
            },
        ],
    },
    {
        title: 'News',
        link: '/news/',
    },
]
