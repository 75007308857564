import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useList } from 'react-use'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { renderOptions, renderStepHeader, renderInnerStepHeader } from './StepShared'

const StepMultiOptions = ({updateStep, stepState, ...props}) => { 
	const { options, innerSteps } = props;
	const [selectedOptions, {updateAt}] = useList(stepState || new Array(innerSteps.length))

	useEffect(() => {
		updateStep(selectedOptions)
	}, [selectedOptions])

	const renderInnerSteps = () => {
		const steps = innerSteps.map((step, i) => {
			const activeOption = selectedOptions[i];

			return (
				<InnerStep
					key={i}
					styles={step.styles}
				>
					{renderInnerStepHeader(step.heading, i + 1)}
					{renderOptions(step.options, (option) => updateAt(i, option), activeOption)}	
				</InnerStep>
			)
		})

		return <InnerSteps>{steps}</InnerSteps>
	}
	
	return (
		<Wrapper>
			{renderStepHeader(props)}
			{renderInnerSteps()}
		</Wrapper>
	)
}


// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`

// Inner Steps

const InnerSteps = styled.div`
	${tw`flex flex-wrap`};
`

const InnerStep = styled.div`
	&:nth-child(1),
	&:nth-child(2) {
		${tw`pb-4`};
		.inner-step-header {
			max-width: 400px;
		}
	}

	&:nth-child(1) {
		${tw`pr-8 border-r border-white`};

		${media.tabPor`
			${tw`border-none pr-0`};
		`}
	}

	&:nth-child(2) {
		${tw`pl-8`};

        ${media.tabPor`
            ${tw`mt-12 pl-0`};
        `}
	}

	&:nth-child(3) {
		${tw`w-full mt-12`};
	}
`

StepMultiOptions.wrapper = css`

`

export default StepMultiOptions
