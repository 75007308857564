import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { useSetState, useMount } from 'react-use'
import styled, { css, createGlobalStyle } from 'styled-components'
import { toWords } from 'number-to-words'
import { Line } from 'rc-progress'
import { compact } from 'lodash'
import tw from 'tailwind.macro'
import NumberFormat from 'react-number-format'

import { Breadcrumbs } from '../'
import { media } from '../../styles/utils'
import { container, padding, hoverState, bgGradient, bgClip, boxShadow } from '../../styles/global'
import { steps, testWizardState } from './data'
import { resolveStep, getPlanByScore } from './utils'

import logo from '../../assets/images/strive-logo-light.svg'
import close from '../../assets/icons/close-icon.svg'

// Number Props

const numberProps = {
    prefix: '$',
    displayType: 'text',
    thousandSeparator: true,
    fixedDecimalScale: true,
    decimalScale: 0,
}

const LoanWizard = props => {
    const [activeStepIndex, setActiveStepIndex] = useState(0)
    const activeStep = steps[activeStepIndex]
    const totalSteps = steps.length - 1
    const complete = activeStepIndex == totalSteps

    const [wizardState, updateWizard] = useSetState({
        // steps: testWizardState.steps,
        steps: [],
    })
    // console.log(wizardState)

    const updateStep = selections => {
        let newSteps = [...wizardState.steps]

        newSteps[activeStepIndex] = selections

        updateWizard({
            steps: newSteps,
        })
    }

    useMount(() => {
        if (props.wizardData) {
            updateWizard({
                steps: props.wizardData.selections.steps,
            })
            setActiveStepIndex(props.wizardData.activeStep)
        }
    })

    const scrollToTop = () => {
        window.scrollTo(0,0)
    }

    useEffect(() => {
        scrollToTop()
    }, [activeStepIndex])

    const canProceed = () => {
        const currentStepSelections = wizardState.steps[activeStepIndex]
        const required = activeStep.options
            ? 1
            : activeStep.innerSteps?.length ||
              activeStep.requiredSelections ||
              false
        if (required == false) return true
        if (required == compact(currentStepSelections).length) return true
    }

    const changeStep = index => {
        if (index < 0 || index > totalSteps) return
        setActiveStepIndex(index)
    }

    const renderCounter = () => {
        let heading = {
            title: activeStepIndex < totalSteps ? `Step ${toWords(activeStepIndex + 1)}` : `Finished!`,
            link: undefined
        }

        let description = {
            title: activeStep.heading,
            link: undefined
        }

        return (
            <Breadcrumbs
                hide_arrow={true}
                heading={heading}
                description={description}
            />
        )
    }

    const renderProgressSummary = () => {
        return (
            <ProgressSummary>
                {activeStepIndex === 0 && (
                    <Description>
                        Watch here to see your <br /> loan get put together
                    </Description>
                )}
                {activeStepIndex !== 0 && <Heading>Your Loan</Heading>}
                {activeStepIndex > 0 && (
                    <>
                        <Item>
                            <NumberFormat
                                value={wizardState.steps[0][0].amount}
                                id="loan-amount"
                                {...numberProps}
                            />
                            <ChangeStep onClick={() => changeStep(0)}>
                                Change
                            </ChangeStep>
                        </Item>
                        <Item>
                            <span>
                                {`- ${wizardState.steps[0][1].length} `}
                                {wizardState.steps[0][1].length > 1
                                    ? 'Months'
                                    : 'Month'}
                            </span>

                            <ChangeStep onClick={() => changeStep(0)}>
                                Change
                            </ChangeStep>
                        </Item>
                    </>
                )}
                {activeStepIndex > 2 &&
                    wizardState.steps.map((item, i) => {
                        if (i >= 2 && i < activeStepIndex) {
                            return item.map((innerItem, inner_index) => {
                                return (
                                    <Item key={inner_index}>
                                        <span>{`- ${innerItem.title}`}</span>
                                        <ChangeStep
                                            onClick={() => changeStep(i)}
                                        >
                                            Change
                                        </ChangeStep>
                                    </Item>
                                )
                            })
                        }
                    })}
            </ProgressSummary>
        )
    }

    const renderProgress = () => {
        return (
            <Progress>
                <ProgressContent>
                    <Heading>Loan Finder</Heading>

                    <Info>
                        <Subheading>Matching your loan</Subheading>
                        <Description>
                            {((activeStepIndex / totalSteps) * 100).toFixed(0)}%
                        </Description>
                    </Info>

                    <ProgressWrapper>
                        <ProgressBar
                            progress={(activeStepIndex / totalSteps) * 100}
                        />
                    </ProgressWrapper>
                </ProgressContent>

                {renderProgressSummary()}
            </Progress>
        )
    }

    const renderStep = () => {
        const StepLayout = resolveStep(activeStep.layout)

        return (
            <>
                <Step styles={StepLayout && StepLayout.wrapper}>
                    {StepLayout && (
                        <StepLayout
                            stepState={wizardState.steps[activeStepIndex]}
                            updateStep={updateStep}
                            wizardState={wizardState}
                            {...activeStep}
                        />
                    )}
                </Step>

                {!complete && (
                    <Nav enabled={canProceed()}>
                        <NextPrev>
                            <Next
                                onClick={() => changeStep(activeStepIndex + 1)}
                            >
                                <span>Next Step</span>
                            </Next>

                            {activeStepIndex > 0 && (
                                <Prev
                                    onClick={() =>
                                        changeStep(activeStepIndex - 1)
                                    }
                                >
                                    {'<< Go back a step'}
                                </Prev>
                            )}
                        </NextPrev>
                    </Nav>
                )}
            </>
        )
    }

    const renderHeader = () => {
        return (
            <Header>
                <Grid>
                    <Left>
                        <HomeLink to="/">
                            <Logo src={logo} />
                        </HomeLink>
                    </Left>
                    <Right>
                        <BackLink
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            <Icon src={close} />
                        </BackLink>
                    </Right>
                </Grid>
            </Header>
        )
    }

    return (
        <>
            <BGStyles/>
            <Wrapper>
                {renderHeader()}
                <Container>
                    {renderCounter()}
                    {renderStep()}
                    {renderProgress()}
                </Container>
            </Wrapper>
        </>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

// background gradient
const BGStyles = createGlobalStyle`
    body {
        ${bgGradient};
    }
`

// Layout

const Wrapper = styled.div`
    ${bgGradient};
    min-height: 100vh;
`

const Container = styled.div`
    ${container}
    ${padding}
    ${tw`pt-8 pb-16`};
`

const Grid = styled.div`
    ${tw`flex`};
`

const Left = styled.div``
const Right = styled.div``

// Header

const HomeLink = styled(Link)``
const Logo = styled.img``
const BackLink = styled.div``
const Icon = styled.img``
const Header = styled.div`
    ${bgGradient};
    ${boxShadow};

    &,
    ${Grid} {
        ${tw`w-full`};
    }

    ${Left}, ${Right} {
        ${tw`flex items-center bg-darkBlue`};
        height: 88px;
    }

    ${Left} {
        ${tw`mr-1 pl-8`};
        flex-grow: 1;

        ${HomeLink} {
            max-width: 140px;
        }
    }

    ${Right} {
        ${BackLink} {
            ${tw`flex items-center h-full px-8 cursor-pointer`};
            ${hoverState};

            ${media.mob`
                ${Icon} {
                    width: 30px;
                    height: 30px;
                }
            `}
        }
    }
`

// Nav (Next / Prev)

const Prev = styled.div``
const Next = styled.div``
const NextPrev = styled.div``

const Nav = styled.div`
    ${tw`flex justify-end mt-12`};

    ${NextPrev} {
        ${tw`flex flex-col items-center`};
    }

    ${Next}, ${Prev} {
        ${tw`cursor-pointer`};
    }

    ${Next} {
        ${bgClip};
        ${tw`py-3 px-8`};
        font-weight: 600; /* semibold */
        font-size: 1.375rem;
        letter-spacing: -0.04em;
        opacity: 0.5;
        pointer-events: none;

        ${props => {
            if (props.enabled)
                return css`
                    pointer-events: all;
                    ${hoverState};
                `
        }}
    }

    ${Prev} {
        ${tw`mt-4 text-orange`};
        font-weight: 600; /* semibold */
        font-size: 14px;
        letter-spacing: -0.02em;
        ${hoverState};
    }
`

// Progress Section

const ProgressContent = styled.div``
const ProgressSummary = styled.div``
const Item = styled.div``
const ChangeStep = styled.div``
const Progress = styled.div`
    ${tw`flex mt-8`};

    ${media.mob`
        ${tw`block`};
    `}

    ${ProgressContent} {
        ${tw`w-3/4 pr-6 pb-4 border-r border-white`};

        ${media.mob`
            ${tw`w-full pr-0 border-r-0`};
        `}

        ${Heading} {
            ${tw`leading-none text-orange`};
            font-weight: 500; /* medium */
            font-size: 14px;
        }

        ${Info} {
            ${tw`flex justify-between py-4`};
        }

        ${Subheading}, ${Description} {
            ${tw`leading-none`};
            font-weight: 600; /* semibold */
            font-size: 1.375rem;
            letter-spacing: -0.02em;
        }

        ${Subheading} {
            ${tw`text-white`};
        }

        ${Description} {
            ${tw`text-orange`};
        }
    }

    ${ProgressSummary} {
        ${tw`w-1/4 pl-6 flex flex-col justify-center`};

        ${media.mob`
            ${tw`w-full pl-0`};

            br {
                display: none;
            }
        `}

        ${Heading} {
            ${tw`mb-2 text-orange`};
            font-weight: 500; /* medium */
            font-size: 14px;
        }

        ${Description} {
            ${tw`text-white`};
            font-weight: 400; /* regular */
            font-size: 14px;
            line-height: 1.57;
        }

        ${Item} {
            ${tw`flex items-center`};

            span {
                ${tw`text-white`};
                font-weight: 600; /* semibold */
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: -0.02em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &#loan-amount {
                    font-size: 1.25rem;
                }
            }
        }

        ${ChangeStep} {
            ${tw`ml-2 text-orange cursor-pointer`};
            font-weight: 500; /* medium */
            font-size: 10px;
        }
    }
`

// Progress Bar

const ProgressWrapper = styled.div`
    ${tw`relative flex w-full h-8 border border-white rounded-full overflow-hidden`};
`

const ProgressBar = styled.div`
    ${tw`absolute inset-0 bg-orange`};
    transition: width 0.5s ease-out;
    will-change: width;

    ${props => {
        return css`
            width: ${props => props.progress}%;
        `
    }}
`

// Step

const Step = styled.div`
    ${tw`flex w-full`};

    > *:first-child {
        flex: 1;
    }

    /* Step specific container styles */

    ${props => {
        if (props.styles) return props.styles
    }}
`

export default LoanWizard
