import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../styles/utils'
import { container, padding, bgImage, bgClip, bgGradient } from '../../styles/global'
import { navigation } from '../../styles/type'
import { headerHeight } from '../Header/Header'
import { Breadcrumbs } from '../'

const PageHeader = props => {

    return (
        <Wrapper page={props.page}>
            {props.hero_image && (
                <Image
                    key={props.hero_image.id}
                    src={props.hero_image.sizes.large}
                >
                    {(src, loading) => {
                        return (
                            <BGImage
                                image={src}
                                title={props.hero_image?.alt}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            )}
            <Container>
                {props.breadcrumbs && (
                    <Breadcrumbs
                        heading={props.breadcrumbs.heading}
                        description={props.breadcrumbs.description}
                        title={props.breadcrumbs.title}
                    />
                )}
                <Text page={props.page}>
                    {props.page_heading && (
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: props.page_heading,
                            }}
                        />
                    )}
                    {props.sub_heading && (
                        <Subheading
                            dangerouslySetInnerHTML={{
                                __html: props.sub_heading,
                            }}
                        />
                    )}
                    {props.short_intro && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: props.short_intro,
                            }}
                        />
                    )}
                </Text>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    background-position: top;
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div`
    ${tw`relative pb-32`};
    padding-top: calc(${headerHeight} + 3rem);
    ${bgGradient};

    ${Image}, ${BGImage} {
        ${tw`absolute inset-0`};
    }

    /* Home page */
    ${props => {
        if (props.page === 'home')
            return css`
                ${tw`pb-64`};
            `
    }}

    /* All other pages */
    ${props => {
        if (props.page !== 'home')
            return css`
                ${media.mob`
                    ${tw`pb-24`};
                `}
            `
    }}
`

const Container = styled.div`
    ${tw`z-10 relative`};
    ${container}
    ${padding}
`

// Breadcrumbs

// see breadcrumbs component

// Content

const Text = styled.div`
    ${tw`w-1/2`};

    ${props => {
        if (props.page === 'investments-single')
            return css`
                ${tw`w-full`};
            `
    }}

    ${media.des`
        width: auto;
        max-width: 500px;
    `}

    ${Heading}, ${Subheading} {
        font-weight: 600; /* semibold */
        font-size: 3.0625rem;
        line-height: 1.24;
        letter-spacing: -0.04em;

        ${media.mob`
            font-size: 32px;
        `}
    }

    ${Heading} {
        ${tw`text-white`};
    }

    ${Subheading} {
        ${tw`text-orange`};
    }

    ${Description} {
        ${tw`text-white mt-8`};
        font-weight: 600; /* semibold */
        font-size: 1.375rem;
        line-height: 1.24;
        letter-spacing: -0.02em;
    }
`

PageHeader.propTypes = {
    breadcrumbs: PropTypes.object,
    hero_image: PropTypes.object,
    page_heading: PropTypes.string,
    sub_heading: PropTypes.string,
    short_intro: PropTypes.string,
    page: PropTypes.string,
}

export default PageHeader
