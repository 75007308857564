import { css } from "styled-components";

import urgent from '../../assets/icons/loan-wizard/urgent.svg'
import notUrgent from '../../assets/icons/loan-wizard/not-urgent.svg'

import cashInjection from '../../assets/icons/loan-wizard/cash-injection.svg'
import lineOfCredit from '../../assets/icons/loan-wizard/line-of-credit.svg'

import calendar1 from '../../assets/icons/loan-wizard/calendar-1.svg'
import calendar12 from '../../assets/icons/loan-wizard/calendar-1-2.svg'
import calendar25 from '../../assets/icons/loan-wizard/calendar-2-5.svg'
import calendar510 from '../../assets/icons/loan-wizard/calendar-5-10.svg'
import calendar10 from '../../assets/icons/loan-wizard/calendar-10.svg'

import mortgage from '../../assets/icons/loan-wizard/mortgage.svg'
import vehicle from '../../assets/icons/loan-wizard/vehicle.svg'



export const steps = [
           {
               heading: `Let's get started.`,
               description: 'Tell us a bit about what you need.',
               layout: 'sliders',
           },
           {
               heading: `What is the loan for?`,
               description: 'Tell us a little bit more about your needs.',
               innerStepHeader: {
                   step: 3,
                   heading: `What purpose are you using the funds for?`,
               },
               layout: 'text',
               requiredSelections: 1,
           },
           {
               heading: `What's important to you?`,
               description: `Let's find out what works for you.`,
               layout: 'multiOptions',
               innerSteps: [
                   {
                       heading: 'How quickly do you need funds?',
                       options: [
                           {
                               title: 'I need the funds straight away',
                               slug: 'urgent',
                               icon: urgent,
                               type: 'large',
                               excludeLoans: ['mediumTerm', 'longTerm'],
                               scoring: [
                                   {
                                       type: 'shortTerm',
                                       points: 1,
                                   },
                               ],
                           },
                           {
                               title: 'The funds aren’t urgently needed',
                               slug: 'notUrgent',
                               icon: notUrgent,
                               type: 'large',
                               scoring: [
                                   {
                                       type: 'mediumTerm',
                                       points: 1,
                                   },
                                   {
                                       type: 'longTerm',
                                       points: 1,
                                   },
                               ],
                           },
                       ],
                   },
                   {
                       heading: `Do you need an injection of cash or a line of credit? `,
                       options: [
                           {
                               title: 'Cash Injection',
                               slug: 'cashInjection',
                               icon: cashInjection,
                               type: 'large',
                               scoring: [
                                   {
                                       type: 'shortTerm',
                                       points: 1,
                                   },
                                   {
                                       type: 'mediumTerm',
                                       points: 1,
                                   },
                                   {
                                       type: 'longTerm',
                                       points: 1,
                                   },
                               ],
                           },
                           {
                               title: 'Line of Credit',
                               slug: 'lineCredit',
                               icon: lineOfCredit,
                               type: 'large',
                               excludeLoans: ['mediumTerm', 'longTerm'],
                               scoring: [
                                   {
                                       type: 'shortTerm',
                                       points: 1,
                                   },
                               ],
                           },
                       ],
                   },
                   {
                       heading: `How long have you been actively trading?`,
                       options: [
                           {
                               title: 'Less than 12 months',
                               slug: '12months',
                               type: 'small',
                               icon: calendar1,
                           },
                           {
                               title: '1-2 years',
                               slug: '1-2 years',
                               type: 'small',
                               icon: calendar12,
                           },
                           {
                               title: '2-5 years',
                               slug: '2-5 years ',
                               type: 'small',
                               icon: calendar25,
                           },
                           {
                               title: '5-10 years',
                               slug: '5-10 years',
                               type: 'small',
                               icon: calendar510,
                           },
                           {
                               title: '10+ years',
                               slug: '10+ years',
                               type: 'small',
                               icon: calendar10,
                           },
                       ],
                   },
               ],
           },
           {
               heading: `Let's talk security.`,
               description: `Security is required for all Strive Financial Loans. Please choose your security asset below.`,
               layout: 'options',
               innerStepHeader: {
                   step: 1,
                   heading: `What type of asset is it?`,
               },
               options: [
                   {
                       title: 'First Mortgage',
                       slug: 'firstMortage',
                       type: 'large',
                       icon: mortgage,
                       scoring: [
                           {
                               type: 'shortTerm',
                               points: 1,
                           },
                           {
                               type: 'mediumTerm',
                               points: 1,
                           },
                           {
                               type: 'longTerm',
                               points: 1,
                           },
                       ],
                   },
                   {
                       title: 'Second Mortgage',
                       slug: 'secondMortage',
                       type: 'large',
                       icon: mortgage,
                       excludeLoans: ['longTerm'],
                       scoring: [
                           {
                               type: 'shortTerm',
                               points: 1,
                           },
                           {
                               type: 'mediumTerm',
                               points: 1,
                           },
                       ],
                   },
                   {
                       title: 'Vehicle or Equipment',
                       slug: 'vehicle or equipment',
                       type: 'large',
                       icon: vehicle,
                       excludeLoans: ['mediumTerm', 'longTerm'],
                       scoring: [
                           {
                               type: 'shortTerm',
                               points: 2,
                           },
                       ],
                   },
               ],
           },
           {
               heading: `Your loan options`,
               layout: 'complete',
           },
       ]

export const plans = [
    {
        type: 'shortTerm',
        heading: `Short Term Business <br/>Bridging Loan`,
        subheading: `You’re looking for a quick solution. Our fast business finance can have you approved
        quickly and funded in just 24 hours.`,
        description: `Let’s get the ball rolling. Provide us with your details and we’ll be in touch today.`
    },
    {
        type: 'mediumTerm',
        heading: `Medium Term <br/>Business Loan`,
        subheading: `You’re looking for a loan which you can pay off over time. Our Medium term finance is
        perfect if you’ve got a plan to complete the loan, but need a little time to get there.`,
        description: `Seize the moment! Provide us with your details and we’ll be in touch today.`
    },
    {
        type: 'longTerm',
        heading: `Long Term <br/>Business Loan`,
        subheading: `You’ve got a big opportunity and need to pay off the funds over a longer period. Our long
        term finance is perfect for businesses who have a long term plan for growth.`,
        description: `You’ve got the plan, we’ve got the funds! Provide us with your details and we’ll be in touch
        today.`
    },
]

export const testWizardState = {
    "steps": [
        [
            {
                "amount": 656064
            },
            {
                "length": 23
            }
        ],
        [
            {
                "text": "Test text"
            }
        ],
        [
            {
                "title": "The funds aren’t urgently needed",
                "slug": "notUrgent",
                "icon": null,
                "scoring": [
                    {
                        "type": "longTerm",
                        "points": 1
                    }
                ]
            },
            {
                "title": "Cash Injection",
                "slug": "cashInjection",
                "icon": null,
                "scoring": [
                    {
                        "type": "shortTerm",
                        "points": 1
                    },
                    {
                        "type": "mediumTerm",
                        "points": 1
                    },
                    {
                        "type": "longTerm",
                        "points": 1
                    }
                ]
            },
            {
                "title": "1-2 years",
                "slug": "1-2 years",
                "icon": null
            }
        ],
        [
            {
                "title": "Second Mortgage",
                "slug": "secondMortage",
                "icon": null,
                "scoring": [
                    {
                        "type": "shortTerm",
                        "points": 1
                    },
                    {
                        "type": "mediumTerm",
                        "points": 1
                    }
                ]
            }
        ]
    ]
}