import React, { useState, useRef, useContext } from 'react'
import { navigate } from 'gatsby'
import { useMount, useUnmount } from 'react-use'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { menu } from '../../data/menu'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { container, padding, button } from '../../styles/global'
import { navigation } from '../../styles/type'
import { headerHeight } from '../Header/Header'

import { MobileMenuContext } from '../MobileMenu/context'

const MobileMenu = props => {
    const mobileMenuEl = useRef(null)
    const mobileMenu = useContext(MobileMenuContext)

    useMount(() => disableBodyScroll(mobileMenuEl.current))

    useUnmount(() => clearAllBodyScrollLocks())

    const renderMenu = () => {
        return menu.map((item, i) => {
            return (
                <MenuItem
                    key={i}
                    onClick={() => {
                        navigate(item.link)
                        mobileMenu.toggleActive()
                    }}
                    activeClassName={'active'}
                >
                    {item.title}
                </MenuItem>
            )
        })
    }

    const renderCtaLinks = () => {
        if (!location.pathname) return

        return (
            <>
                <MenuItem
                    onClick={() => {
                        navigate('/contact')
                        mobileMenu.toggleActive()
                    }}
                    activeClassName={'active'}
                >
                    Contact Us
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        navigate('/contact')
                        mobileMenu.toggleActive()
                    }}
                    className={'cta'}
                >
                    Apply Now
                </MenuItem>
            </>
        )
    }

    return (
        <Wrapper ref={mobileMenuEl}>
            <Container>
                <Menu>
                    {renderMenu()}
                    {renderCtaLinks()}
                </Menu>
            </Container>
        </Wrapper>
    )
}

// Shared

const Wrapper = styled.div`
    ${tw`w-full h-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const ExternalLink = styled(OutboundLink)``
const MenuItem = styled.div``
const Menu = styled.div`
    ${tw`flex flex-col items-start pt-40 pb-8`};

    ${MenuItem}, ${ExternalLink} {
        ${tw`block py-6 text-white`};
        font-weight: 600; /* semibold */
        font-size: 22px;
        letter-spacing: -0.02em;

        &:not(.cta) {
            ${tw`w-full border-b border-darkerBlue`};
            &.active {
                ${tw`text-orange`};
            }
        }

        &.cta {
            ${button};
            font-weight: 600; /* semibold */
            font-size: 22px;
            letter-spacing: -0.02em;
            ${tw`inline-block my-6 py-3 px-10`};
        }
    }
`

export default MobileMenu
