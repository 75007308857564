export const resolveTailwindClass = (width) => {
    // Map layout strings to blocks

    const widths = {
        '1/2': 'w-1/2 mob:w-full',
        '1/3': 'w-1/3 tabPor:w-1/2 mob:w-full',
        'full': 'w-full',
    }
    
    return widths[width]
}


