import { BlockText, BlockImage } from './templates'

export const resolveBlock = layout => {
    let block

    // Map layout strings to blocks

    const blocks = {
        text: BlockText,
        image: BlockImage,
    }

    return blocks[layout]
}
