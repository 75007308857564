import React from 'react'
import Transition from '../../transition'
import styled from 'styled-components'
import { MetaTags, Header, Footer } from '../'

const Layout = ({ children, noHeader, noFooter, meta }) => {
    return (
        <>
            <MetaTags {...meta} />
            <Wrapper>
                {!noHeader && <Header />}

                <Transition>
                    {children}
                    {!noFooter && <Footer />}
                </Transition>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100%;
`

export default Layout
